<template>
	<div style="height: 100vh;">
        
        <div id="container"></div>
        <p id="info" style="margin-top:10px;"></p>
        
    </div>
		
</template>

<script>
	
	
	export default {
		data() {
			return {
				
			}
		},
        mounted() {
            // this.txmap()
        },
        methods:{
            // txmap(){
            //     var center = new window.qq.maps.LatLng(25.096991,104.899523);
            //     var map = new window.qq.maps.Map(document.getElementById('container'),{
            //         center: center,
            //         zoom: 15
            //     });
            
            //     var anchor = new window.qq.maps.Point(32, 55),
            //         size = new window.qq.maps.Size(64, 64),
            //         origin = new window.qq.maps.Point(0, 0),
            //         icon = new window.qq.maps.MarkerImage('./dingwei_icon.png', size, origin, anchor);
                    
            //     new window.qq.maps.Marker({
            //         icon: icon,
            //         map: map,
            //         position:map.getCenter()
            //     });
            // }
        }
	}
</script>

<style>
    html,body{
        width:100%;
        height:100%;
    }
    #container{
        width:100%;
        height:100%;
    }
      *{
        margin:0px;
        padding:0px;
    }
    .csssprite{
        width: 100%;
    }
</style>
